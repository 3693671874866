import {createContext, useEffect, useState} from "react";
import axios from "axios";
import {DOMAIN} from "../config";


export const MessagesContext = createContext({})


export const MessagesProvider = ({children}) => {

    let [response, setResponse] = useState({})

    useEffect(() => {
        axios.get(`${DOMAIN}/api/v1/messages/`).then((res)=>{
            setResponse(res.data)
        }).catch((res)=>{
            console.log(res)
        })
    }, [])

    return (
        <MessagesContext.Provider value={response}>
            {children}
        </MessagesContext.Provider>
    )
}












