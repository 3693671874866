import React, {useContext} from 'react'
import HeroBgAnimation from '../HeroBgAnimation'
import {
    HeroContainer,
    HeroBg,
    HeroLeftContainer,
    Img,
    HeroRightContainer,
    HeroInnerContainer,
    TextLoop,
    Title,
    Span,
    SubTitle,
    SocialMediaIcons,
    SocialMediaIcon,
    ResumeButton
} from './HeroStyle'
import HeroImg from '../../images/HeroImage.png'
import Typewriter from 'typewriter-effect';
import {Bio} from '../../data/constants';
import {MessagesContext} from "../../context/MessagesContext";

const HeroSection = () => {

    const messages = useContext(MessagesContext)

    console.log(messages)


    return (
        <div id="about">
            <HeroContainer>
                <HeroBg>
                    <HeroBgAnimation/>
                </HeroBg>
                <HeroInnerContainer>
                    <HeroLeftContainer id="Left">
                        <Title>Salom men <br/> {messages.name}</Title>
                        <TextLoop>
                            men
                            <Span>
                                <Typewriter
                                    options={{
                                        strings: Bio.roles,
                                        autoStart: true,
                                        loop: true,

                                    }}
                                />
                            </Span>
                        </TextLoop>
                        <SubTitle>{messages.description}</SubTitle>
                        <ResumeButton href={messages.resume} target='display'>Resume</ResumeButton>
                    </HeroLeftContainer>

                    <HeroRightContainer id="Right">

                        <Img src={HeroImg} alt="hero-image"/>
                    </HeroRightContainer>
                </HeroInnerContainer>

            </HeroContainer>
        </div>
    )
}

export default HeroSection