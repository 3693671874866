import React, { useContext, useEffect } from 'react'
import { useState } from 'react'
import { Container, Wrapper, Title, Desc, CardContainer, ToggleButtonGroup, ToggleButton, Divider } from './ProjectsStyle'
import ProjectCard from '../Cards/ProjectCards'
import { projects } from '../../data/constants'
import { MessagesContext } from '../../context/MessagesContext'
import axios from 'axios'
import { DOMAIN } from '../../config'


const Projects = ({ openModal, setOpenModal }) => {
    const [toggle, setToggle] = useState('all');

    const messages = useContext(MessagesContext)

    const [projects,setProjects] = useState([])


    useEffect(()=>{
        axios.get(`${DOMAIN}/api/v1/projects/`).then(res=>{
            setProjects(res.data)
            console.log(projects);
        }).catch(res=>{
            console.log(res);
        })
    },[])


    return (
        <Container id="projects">
            <Wrapper>
                <Title>{messages.projects}</Title>
                <Desc style={{ marginBottom: "20px" }}>
                    {messages.projects_desc}
                </Desc>

                <CardContainer>
                    {
                        projects.map((project) => (
                                <ProjectCard project={project} openModal={openModal} setOpenModal={setOpenModal} />
                            ))
                    }

                </CardContainer>
            </Wrapper>
        </Container>
    )
}

export default Projects